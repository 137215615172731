<template>
  <div>
    <LayoutModelIndex
      v-bind="this.$store.state.stone_model[_modelName]"
      :params="_params"
      :label="_label"
      :urlModelName="_urlModelName"
      :getUrl="_getUrl"
      :createUrl="_createUrl"
      bindingKey="index_my"
      :dialogCreateSubmitUrl="_dialogCreateSubmitUrl"
      :getDialogUpdateSibmitUrl="$_getDialogUpdateSibmitUrl"
      :getDeleteSubmitUrl="$_getDeleteSubmitUrl"
      :getReadFetchUrl="$_getReadFetchUrl"
      :getReadUrl="$_getReadUrl"
    ></LayoutModelIndex>
  </div>
</template>

<script>
export default {
  methods: {
    $_getReadUrl(item) {
      return `/my/${this._modelName}/${item.id}`;
    },
    $_getReadFetchUrl(item) {
      return `/my/${this._modelName}/${item.id}`;
    },
    $_getDialogUpdateSibmitUrl(item) {
      return `/my/${this._modelName}/${item.id}`;
    },
    $_getDeleteSubmitUrl(item) {
      return `/my/${this._modelName}/${item.id}`;
    },
  },
  computed: {
    _dialogCreateSubmitUrl() {
      return `/my/${this._modelName}`;
    },
    _getUrl() {
      return `/my/${this._urlModelName}`;
    },
    _createUrl() {
      return `/my/${this._urlModelName}/create`;
    },
    _modelName() {
      return this.$route.meta.modelName;
    },
    _params() {
      let _params = {};
      if (this.$route.meta.params) {
        _params = this.$route.meta.params;
      }
      return _params;
    },
    _label() {
      let _label = "";
      if (this.$route.meta && this.$route.meta.label) {
        _label = this.$route.meta.label;
      }
      if (this.$store.state.stone_model[this._modelName].label) {
        _label = this.$store.state.stone_model[this._modelName].label;
      }
      return _label;
    },
    _urlModelName() {
      return this.$route.meta.urlModelName
        ? this.$route.meta.urlModelName
        : this._modelName;
    },
  },
  mounted() { },
};
</script>